import ImageTextSection from "../../partials/sections/ImageTextSection/ImageTextSection";
import ArtSection from "../../partials/sections/ArtSection/ArtSection";
import GallerySection from "../../partials/sections/GallerySection/GallerySection";
import LeaderboardSection from "../../partials/sections/LeaderboardSection/LeaderboardSection";
import ResultSection from "../../partials/sections/ResultSection/ResultSection";
import UserStorySection from "../../partials/sections/UserStorySection/UserStorySection";
import Footer from "../../partials/components/Footer/Footer";

import phase1Img from "../../assets/phase-1-img.gif";
import phase2Img from "../../assets/phase-2-img.gif";
import phase3Img from "../../assets/connections.gif";


const MainPage = () => {
  return (
    <div>
      <ArtSection/>
      <section id="how-it-works">
        <h3 className="title-h2">How It Works</h3>
      </section>
      
      <ImageTextSection 
        id="wrappedNFT"
        direction="rightImg"
        uptitle="Phase 1"
        title="Combining Art + Tech"
        desc="We designed a series of NFT artworks with 25 unique base designs, each representing one of the agencies in our network. On top of it, we built a unique employee experience that unlocked real-world perks, prizes and experiences for every employee that participated."
        subtitle="Designed by"
        img={phase1Img}
      />

      <ImageTextSection 
        id="unwrapART"
        direction="leftImg"
        uptitle="Phase 2"
        title="Releasing the NFTs"
        desc="We invited our 3,000 marketers around the world — many of whom were interacting with blockchain for the first time — to claim their artwork by creating a Web3 wallet and minting an NFT. In creating their own NFTs, Plus Company employees around the globe learned more about Web3 and its capabilities, leaning into the unfamiliar - to fantastic results."
        subtitle="Conceived by"
        img={phase2Img}
      />

      <ImageTextSection 
        id="connectEvolve"
        direction="rightImg"
        uptitle="Phase 3"
        title="Evolving by Connecting"
        desc="Employees embraced the art of connection, watching their NFTs evolve with every new connection they made. NFTs interacted with one another to create a one-off piece of art for every employee. Plus Connect is the first of its kind. It’s an employee NFT program that fosters connections, collaborations, and let’s be honest - it’s beautifully cool."
        subtitle="Brough to life by"
        img={phase3Img}
        // iframe='https://www.plusconnect.io/api/nft-preview?id=1&mode=card'
      />

      <GallerySection/>
      <LeaderboardSection/>
      <ResultSection/>
      <UserStorySection/>
      <Footer/>
    </div>
  )
}

export default MainPage;