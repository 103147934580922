import styles from './userStorySection.module.scss';
import screenshot from './../../../assets/screenshot.png';
import HiddenTitle from '../../components/HiddenTitle/HiddenTitle';

const UserStorySection = () => {
  return (
    <section className={styles.userStorySection}>
      <h3 className="title-h2">User Stories</h3>
      <div className={styles.contentWrap}>
      <p className={styles.descTop}>"Traditional agency networks tend to be top-down, heavy-handed, and bureaucratic. Fresh, original thinking doesn't easily flourish under those conditions, so Plus Company is taking a contrarian approach: members of the Innovation DAO will be given the trust, support, and resources they need to nurture an independent spirit and creative culture" <span><br/>- CEO, Plus company</span></p>
      <p className={styles.descMid}>"I was hoping NFTs were dead. So, when I was invited by my agency network to "claim my NFT," I first ignored the email. [...] As you can see from my NFT - I didn't get to connect with every agency, BUT I did learn a lesson about not ignoring emails. More importantly, this hands on experience brought to mind dozens of ideas about how NFTs can be reimagined, humanized and useful for brands." <span><br/>- President, Plus company</span></p>
        {/* <img  className={styles.image} src={screenshot} alt='user story screenshot'/> */}
        <p className={styles.descBottom}>"In today's work environment, learning is simply more than seeing. Ensuring our employees have unfettered access to new technologies, exposure to new ways of thinking, and experience across disciplines, is essential to staying relevant and ahead of the curve for producing groundbreaking work. It's more than work-life balance. We're now in the age of work-life-learn," <span><br/>- Vice President and Regional Lead Middle East, Plus company</span></p>
        {/* <div className={styles.subtitleWrap}>
          <div className={styles.subimg} />
          <p className={styles.subtitle}>Amplified by <HiddenTitle/></p>
        </div> */}
      </div>
  
    </section>
  )
}

export default UserStorySection;