import styles from './gallerySection.module.scss';
import image from './../../../assets/gallery-image.gif'

const GallerySection = () => {
  return (
    <section id="gallery" className={styles.gallery}>
      <h3 className="title-h2">Gallery</h3>
      <div className={styles.desc}>Each NFT is like a snowflake - stunningly unique. An astounding 23,000 connections contributed to evolving each of the plusconnect NFTs into individual pieces of art for each employee. Did we mention that we got 19% higher employee engagement than the global benchmark?</div>
      <a href='https://www.plusconnect.io/gallery' className={styles.imgWrap} target="_blank" rel="noopener noreferrer">
        <img className={styles.img} src={image} alt="gallery screenshot" />
      </a>
    </section>
  )
}

export default GallerySection;