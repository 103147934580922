import styles from './leaderboardSection.module.scss';
import leaderboard from './../../../assets/leaderboard.gif'

const LeaderboardSection = () => {
  return (
    <section id="leaderboard" className={styles.leaderboard}>
      <h3 className="title-h2">Leaderboard</h3>
      <div className={styles.desc}>There was more at stake than just perks and prizes. There were bragging rights too. The excitement built as employees competed against each other to evolve their art and gain more connections. Owning the leaderboard meant you had a bucket of new connections across Plus Company, a fantastic NFT and understood more about Web 3 than you did before. That's a win-win-win.</div>
      <a href='https://www.plusconnect.io/leaderboard' className={styles.imgWrap} target="_blank" rel="noopener noreferrer">
        <img className={styles.img} src={leaderboard} alt="leaderboard"/>
      </a>
    </section>
  )
}

export default LeaderboardSection;