import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Scrollspy from 'react-scrollspy'

export default function MobileSidebar() {
  const [state, setState] = React.useState({
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
    <nav className='navMobile'>
        <Scrollspy  className="navListMobile" items={ ['artSection', 'wrappedNFT', 'unwrapART', 'connectEvolve', 'gallery', 'leaderboard', 'results'] } currentClassName="active">
          <li><a href="#artSection" className='navlinkMobile'>Overview</a></li>
          <li><a href="#how-it-works" className='navlinkMobile'>How It Works</a></li>
          {/* <li><a href="#unwrapART" className='navlinkMobile'>NFT Gallery</a></li> */}
          {/* <li><a href="#connectEvolve" className='navlinkMobile'>Leaderboard</a></li> */}
          <li><a href="#gallery" className='navlinkMobile'>NFT Gallery</a></li>
          <li><a href="#leaderboard" className='navlinkMobile'>Leaderboard</a></li>
          <li><a href="#results" className='navlinkMobile'>Results</a></li>
        </Scrollspy>
      </nav>
    </Box>
  );

  return (
    <div>
      {/* {['right', 'bottom'].map((anchor) => ( */}
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><MenuIcon id="burgerIcon" color='warning' fontSize="large"/></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}