import styles from './footer.module.scss'
// import { ReactComponent as LogoSmall } from './../../../assets/pluscompany-logo.svg'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p>Made with ❤️ by 4 agencies</p>
    </footer>
  )
}

export default Footer;