import styles from './resultSection.module.scss';
import CountUp from 'react-countup';

const ResultSection = () => {
  return (
    <section id="results" className={styles.resultSection}>

      <h3 className="title-h2">The Result</h3>

      <div className={styles.numbersWrap}>

        <div className={styles.numberWrap}>
          <CountUp className={styles.number} end={23} duration={2} suffix="K+" enableScrollSpy={true} scrollSpyOnce={true}/>
          <div className={styles.title}>Connections made <br />Within 90 days</div> 
          {/* <div className={styles.subtitle}>Connections made <br />Within 90 days</div>  */}
        </div>

        <div className={styles.numberWrap}>
          <CountUp className={styles.number} end={800} suffix="+" separator="," duration={2} enableScrollSpy={true} scrollSpyOnce={true}/>
          <div className={styles.title}>Employees claimed <br />their first ever NFT</div> 
          {/* <div className={styles.subtitle}>Employees claimed <br />their first ever NFT</div>  */}
        </div>

        <div className={styles.numberWrap}>
          <CountUp className={styles.number} end={3000} duration={2} enableScrollSpy={true} scrollSpyOnce={true}/>
          <div className={styles.title}>NFT holders join <br />Our Innovation DAO</div> 
          {/* <div className={styles.subtitle}>NFT holders join <br />Our Innovation DAO</div>  */}
        </div>

      </div>
    </section>
  )
}

export default ResultSection;
