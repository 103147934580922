import FullScreenDialog from '../../components/VideoPopup/VideoPopup';
import styles from './artSection.module.scss';

const ArtSection = () => {
  return (
    <section id="artSection" className={styles.artSection}>
      {/* <div className={styles.uptitle}>Connecting</div>
      <h3 className="title-h2">Art + Technology</h3> */}
      <div className={styles.subtitle}>A first of its kind, employee advocacy & learning experience</div>
      <div className={styles.desc}>In November 2021, a new agency network was born. Plus Company is a network of 25 agencies, spread across five continents. We needed to find a way to get to know each other and connect. But we also saw an opportunity to show our employees the power of Web3 in a way that was natural, engaging, and just plain fun for those who knew nothing about it.</div>
      <div className={styles.buttonsWrap}>
        {/* <FullScreenDialog/> */}
        <a href='https://www.plusconnect.io/' className="customButton" target="_blank" rel="noopener noreferrer">Visit plusconnect</a>
      </div>
    </section>
  )
}


export default ArtSection;