import styles from './imageTextSection.module.scss';
import HiddenTitle from '../../components/HiddenTitle/HiddenTitle';

const ImageTextSection 
  = ({id,
      direction, 
      uptitle, 
      title, 
      desc, 
      subtitle, 
      subimg, 
      img, 
      iframe}) => {
  return (
    <section id={id} className={direction === 'rightImg' ? styles.rightImg : styles.leftImg}>
      
      <div className={`${styles.textContentWrap} textContentWrap`}>
        <h4 className={styles.uptitle}>{uptitle}</h4>
        <h3 className="title-h3">{title}</h3>
        <p className={styles.desc}>{desc}</p>
        <div className={styles.subtitleWrap}>
          <img className={styles.subimg} src={subimg} alt={subimg} />
          <p className={styles.subtitle}>{subtitle} <HiddenTitle/> </p>
        </div>
      </div>

      {img ?  <div className={styles.imageWrap}>
          <img className={styles.img} src={img} alt={img}/> 
      </div> :  
      <div id='frameWrap'>
        <iframe  
          id='pluscard'
          frameBorder="0"
          title='Plusconnect Card' 
          src={iframe} 
          allowFullScreen={true}
          >
          </iframe>
      </div>
      }

     

    </section>
  )
}

export default ImageTextSection;