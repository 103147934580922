import React, { useState, useRef, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './styles/index.scss';
import Scrollspy from 'react-scrollspy'

import MainPage from './pages/MainPage/MainPage';

import logoFull from './assets/plusconnect-logo-full.gif'
import logoSmall from './assets/logo-small.png'
import { ReactComponent as LogoSmall } from './assets/pluscompany-logo.svg';
import MobileSidebar from './partials/components/MobileSidebar/MobileSidebar';

const App = () => {

  // const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const [sticky, setSticky] = useState({ isSticky: true, offset: 100 });
  const headerRef = useRef(null);

  // handle scroll event
  // const handleScroll = (elTopOffset, elHeight) => {
  //   if (window.pageYOffset > (elTopOffset + elHeight)) {
  //     setSticky({ isSticky: true, offset: elHeight });
  //   } else {
  //     setSticky({ isSticky: false, offset: 0 });
  //   }
  // };

  // useEffect(() => {
  //   window.scrollTo({top: 0, behavior: 'smooth'});

  //   var header = headerRef.current.getBoundingClientRect();
  //   const handleScrollEvent = () => {
  //     handleScroll(header.top, header.height)
  //   }

  //   setTimeout(() => {
  //     window.addEventListener('scroll', handleScrollEvent);
  //     return () => {
  //       window.removeEventListener('scroll', handleScrollEvent);
  //     };
  //   }, 1000);

  // }, []);

  return (
    // <div className="App" style={{ marginTop: sticky.offset }}>
    <div className="App">

      <section className="h_video_Section">
        <iframe height="100%" src="https://www.youtube.com/embed/vSv1tMKxr_o" title="YouTube video player Plusconnect" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </section>
      <header className="header">
        <img className="logoFull" src={logoFull} alt='logo plusconnect' />
        {/* <p className="desc">A first of its kind gamified employee experience by</p> */}
      </header>

      <div id="sticky-header" className={`navbar${sticky.isSticky ? ' sticky' : ''}`} ref={headerRef}>
        <div className='headerDesktop'>
          <div className="logoSmall"><LogoSmall/></div>
          <nav className='nav'>
            <Scrollspy  className="navList" items={ ['artSection', 'wrappedNFT', 'unwrapART', 'connectEvolve', 'gallery', 'leaderboard', 'results'] } currentClassName="active">
              <li><a href="#artSection" className='navlink'>Overview</a></li>
              <li><a href="#how-it-works" className='navlink'>How It Works</a></li>
              {/* <li><a href="#unwrapART" className='navlink'>Unwrap the ART</a></li> */}
              {/* <li><a href="#connectEvolve" className='navlink'>Connect & Evolve</a></li> */}
              <li><a href="#gallery" className='navlink'>NFT Gallery</a></li>
              <li><a href="#leaderboard" className='navlink'>Leaderboard</a></li>
              <li><a href="#results" className='navlink'>Results</a></li>
            </Scrollspy>
          </nav>
        </div>

        <div className='headerMobile'>
          <img className='burgerLogo' alt='burger plusconnect Logo' src={logoSmall}/>
          <MobileSidebar/>
        </div>
      
      </div>


      <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </div>
  );
}

export default App;
